import { deserialize } from "../utils/sessionStorageUtils";
import { getPasscode, leave, login, logout } from "../../redux/slices/authSlice";
import { patchUser, resetUserErrors, saveUser } from "../../redux/slices/userSlice";
import { selectAuthEmail, selectAuthStatus } from "../../redux/selectors/authSelector";
import { selectStatus, selectUserData, selectUserError } from "../../redux/selectors/userSelector";
import { useAppLocation } from "./useAppLocation";
import { useDispatch, useSelector } from "react-redux";

export const useUser = () => {
  const dispatch = useDispatch();
  const { navigateTo } = useAppLocation();

  const userData = useSelector(selectUserData);
  const userStatus = useSelector(selectStatus);
  const userError = useSelector(selectUserError);

  const postUser = data => dispatch(saveUser(data));
  const updateUser = (data) => dispatch(patchUser(data));
  const sessionPersonalInfo = deserialize("session_personalInfo");
  const sessionPersonalData = deserialize("session_personalData");

  /* login, logout */
  const authStatus = useSelector(selectAuthStatus);
  const email = useSelector(selectAuthEmail);
  const submitEmail = email => dispatch(getPasscode(email));
  const submitPasscode = passcode => dispatch(login({ email, passcode: passcode.toString(), navigateTo }));
  const logoutUser = () => dispatch(logout({ navigateTo }));

  const clearUserErrors = () => dispatch(resetUserErrors());

  const leavePage = () => dispatch(leave());

  return {
    userData,
    userStatus,
    userError,
    sessionPersonalInfo,
    sessionPersonalData,
    authStatus,
    email,
    postUser,
    updateUser,
    submitEmail,
    submitPasscode,
    logoutUser,
    clearUserErrors,
    leavePage
  };
};