import { YourInfoView } from "./index";
import { getCancers } from "../../../redux/slices/cancersSlice";
import {
  useAppLocation,
  useEnrollment,
  useEnrollmentDialog,
  useEnrollmentStepper,
  useUser
} from "shared/hooks";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const YourInfoContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCancers());
  }, []);

  const {
    isNonEligibleDialogOpen,
    setNonEligibleDialog,
    setVisibilityPersonalInfo,
    setVisibilityScreening
  } = useEnrollmentDialog();

  const { updateEnrollmentStep } = useEnrollmentStepper();

  const {
    isScreening,
    isPersonalInfo,
    cancers,
  } = useEnrollment();

  const { sessionPersonalInfo, postUser, userStatus, userError, logoutUser, clearUserErrors } = useUser();
  const { navigateTo } = useAppLocation();

  const [ email, setEmail ] = useState("");

  const handleSubmitScreeningForm = (formData) => {
    if (formData.countryTissue === "No" || formData.tissueExists === "No") {
      setNonEligibleDialog(true);
      setVisibilityScreening(true);
      setVisibilityPersonalInfo(false);
    } else {
      setVisibilityScreening(false);
      setVisibilityPersonalInfo(true);
      setEmail(formData.email);
    }
  };

  const handleSubmitPersonalInfoForm = formData => postUser(formData);

  const handleCloseNonEligibilityDialog = () => setNonEligibleDialog(false);

  const handleGoToNextStep = () => updateEnrollmentStep({ currentEnrollmentStep: 1 });

  const handleNavigateToAccount = () => navigateTo("/dashboard");

  const handleCloseErrorDialog = () => logoutUser();

  const handleClearErrors = () => clearUserErrors();

  return (
    <YourInfoView
      userStatus={userStatus}
      email={email}
      userError={userError}
      cancers={cancers}
      isLogged={!!sessionPersonalInfo}
      isNotEligibleDialogOpen={isNonEligibleDialogOpen}
      isScreeningForm={isScreening}
      isPersonalInfoForm={isPersonalInfo}
      onSubmitScreeningForm={handleSubmitScreeningForm}
      onSubmitPersonalInfoForm={handleSubmitPersonalInfoForm}
      onCloseNonEligibleDialog={handleCloseNonEligibilityDialog}
      onGoToNextStep={handleGoToNextStep}
      onNavigateToAccount={handleNavigateToAccount}
      onCloseErrorDialog={handleCloseErrorDialog}
      clearErrors={handleClearErrors}
    />
  );
};

export default YourInfoContainer;