import { ConsentService } from "services/concentService";
import { call, put, select } from "redux-saga-test-plan/matchers";
import {
  failure,
  pending,
  setConsentStep,
  success
} from "../../redux/slices/consentSlice";
import { getConsent } from "../../redux/slices/consentSlice";
import { selectConsent } from "../selectors/consentSelector";
import { setEnrollmentStep } from "../../redux/slices/enrollmentSlice";
import { takeLatest } from "redux-saga/effects";

export const consentSaga = [
  takeLatest(getConsent, handleFetchConsent),
];

function* handleFetchConsent({ payload }) {

  const { study_id } = payload;

  const patternTeamContactInfo = {
    email: "info@pattern.org",
    phone: "(919) 827-8373",
    personnel: [{name: "Pattern.org Team", title: ""}]
  };

  try {
    yield put(pending());
    const prevConsent = yield select(selectConsent);
    let response = yield call(ConsentService.getConsent, study_id);
    if (response.contact_info.personnel.length === 0) {
      response = { ...response, contact_info: patternTeamContactInfo};
    }
    yield put(success(response));
    if (prevConsent && prevConsent.id !== response.id) {
      yield put(setConsentStep({ currentConsentStep: 0, maxConsentStep: 0 }));
    }
    yield put(setEnrollmentStep({ currentEnrollmentStep: 2, maxEnrollmentStep: 2 }));
  } catch (error) {
    yield put(failure(error));
  }
}