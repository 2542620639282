import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  personalInfo: null,
  status: "idle",
  passcode: null,
  email: "",
  error: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    getPasscode(action){},
    // eslint-disable-next-line no-unused-vars
    logout(action){},
    // eslint-disable-next-line no-unused-vars
    login(action){},
    // eslint-disable-next-line no-unused-vars
    leave(action){},
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.email = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  },
});

export const {
  getPasscode,
  login,
  logout,
  leave,
  pending,
  success,
  failure
} = authSlice.actions;
export default authSlice.reducer;