import { DonationInfoService } from "services/donationInfoService";
import { call, put, select } from "redux-saga-test-plan/matchers";
import {
  failure, getUser,
  pending,
  success
} from "../../redux/slices/userSlice";
import { saveDonationInfo } from "../slices/donationInfoSlice";
import { selectStudyId } from "../selectors/consentSelector";
import { serializeDonationInfo } from "shared/utils/formUtils";
import { takeLatest } from "redux-saga/effects";

export const donationInfoSaga = [
  takeLatest(saveDonationInfo, handleSaveDonationInfo),
];

function* handleSaveDonationInfo({ payload }) {
  try {
    yield put(pending());
    const id = yield select(selectStudyId);
    const donationInfoData = serializeDonationInfo(payload, id);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(DonationInfoService.saveDonationInfo, {...donationInfoData, timezone});
    if (response) {
      yield put(getUser());
    }
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}