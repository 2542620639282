import { combineReducers } from "redux";
import authSlice from "./slices/authSlice";
import cancersSlice from "./slices/cancersSlice";
import consentSlice from "./slices/consentSlice";
import donationInfoSlice from "./slices/donationInfoSlice";
import enrollmentSlice from "./slices/enrollmentSlice";
import foundationPartnersSlice from "./slices/foundationPartnersSlice";
import staticStuffSlice from "./slices/staticStuffSlice";
import studyInfoSlice from "./slices/studyInfoSlice";
import userSlice from "./slices/userSlice";

const combinedReducer = combineReducers({
  enrollment: enrollmentSlice,
  cancers: cancersSlice,
  foundationPartners: foundationPartnersSlice,
  user: userSlice,
  auth: authSlice,
  studyInfo: studyInfoSlice,
  consent: consentSlice,
  donationInfo: donationInfoSlice,
  staticStuff: staticStuffSlice
});

export default combinedReducer;