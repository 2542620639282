import { UserService } from "services/userService";
import { call, put } from "redux-saga-test-plan/matchers";
import { clearSession, serialize } from "shared/utils/sessionStorageUtils";
import {
  failure,
  getPasscode,
  leave,
  login,
  logout,
  pending,
  success
} from "../../redux/slices/authSlice";
import { getStudyInfo } from "../slices/studyInfoSlice";
import { setUser } from "../slices/userSlice";
import { takeLatest } from "redux-saga/effects";

export const authSaga = [
  takeLatest(getPasscode, handleGetPasscode),
  takeLatest(login, handleLogin),
  takeLatest(logout, handleLogout),
  takeLatest(leave, handleLeave)
];

function* handleGetPasscode({ payload }) {
  try {
    yield put(pending());
    yield call (UserService.getPasscode, payload);
    yield put(success(payload));
  } catch (error) {
    yield put(failure(error));
  }
}

function* handleLogin({ payload }) {
  const { email, passcode, navigateTo } = payload;
  try {
    yield put(pending());
    const response = yield call (UserService.loginUser, email, passcode);
    yield put(success(response));
    const { data: { personal }} = response;
    yield put(setUser(response));
    yield put(getStudyInfo({ cancer_id: personal.cancer_diagnosis.id }));
    serialize("personalInfo", personal);
    serialize("personalData", response.data);
    navigateTo("/dashboard");
  } catch (error) {
    yield put(failure(error));
  }
}

function handleLogout({ payload }) {
  const navigateTo = payload.navigateTo;
  clearSession();
  navigateTo("/");
}

function handleLeave() {
  clearSession();
}