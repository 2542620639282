import { selectCurrentEnrollmentStep, selectMaxEnrollmentStep } from "../../redux/selectors/enrollmentSelector";
import { setEnrollmentStep } from "../../redux/slices/enrollmentSlice";
import { useDispatch, useSelector } from "react-redux";

export const useEnrollmentStepper = () => {
  const dispatch = useDispatch();

  const currentEnrollmentStep = useSelector(selectCurrentEnrollmentStep);
  const maxEnrollmentStep = useSelector(selectMaxEnrollmentStep);
  const updateEnrollmentStep = payload => dispatch(setEnrollmentStep(payload));

  return {
    currentEnrollmentStep,
    maxEnrollmentStep,
    updateEnrollmentStep
  };
};