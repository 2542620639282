import { EnrollmentService } from "services/enrollmentService";
import { call, put } from "redux-saga-test-plan/matchers";
import {
  failure,
  getCancers,
  pending,
  success
} from "../../redux/slices/cancersSlice";
import { takeLatest } from "redux-saga/effects";

export const cancersSaga = [
  takeLatest(getCancers, handleFetchCancers)
];

function* handleFetchCancers() {
  const notListed = {
    id: 0,
    display: "Not Listed",
    value: ""
  };

  try {
    yield put(pending());
    const response = yield call(EnrollmentService.getCancers);
    const cancers = [...response, notListed];
    yield put(success(cancers));
  } catch (error) {
    yield put(failure(error));
  }
}