import { all } from "redux-saga/effects";
import { authSaga } from "./sagas/authSaga";
import { cancersSaga } from "../redux/sagas/cancersSaga";
import { consentSaga } from "../redux/sagas/consentSaga";
import { donationInfoSaga } from "./sagas/donationInfoSaga";
import { staticStuffSaga } from "./sagas/staticStuffSaga";
import { studyInfoSaga } from "../redux/sagas/studyInfoSaga";
import { userSaga } from "../redux/sagas/userSaga";

// watcher
export function* rootSaga() {
  yield all([
    ...authSaga,
    ...cancersSaga,
    ...userSaga,
    ...studyInfoSaga,
    ...consentSaga,
    ...donationInfoSaga,
    ...staticStuffSaga
  ]);
}