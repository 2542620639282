import { getStudyInfo } from "../../redux/slices/studyInfoSlice";
import { resetEnrollmentState } from "../../redux/slices/enrollmentSlice";
import { saveDonationInfo } from "../../redux/slices/donationInfoSlice";
import { selectCancers } from "../../redux/selectors/cancersSelector";
import {
  selectPersonalInfo,
  selectScreening
} from "../../redux/selectors/enrollmentSelector";
import { selectStudyInfo, selectStudyInfoStatus } from "../../redux/selectors/studyInfoSelector";
import { useDispatch, useSelector } from "react-redux";

export const useEnrollment = () => {
  const dispatch = useDispatch();

  const isScreening = useSelector(selectScreening);
  const isPersonalInfo = useSelector(selectPersonalInfo);
  const cancers = useSelector(selectCancers);

  // study
  const studyInfoStatus = useSelector(selectStudyInfoStatus);
  const studyInfo = useSelector(selectStudyInfo);
  const fetchStudyInfo = id => dispatch(getStudyInfo({cancer_id: id }));

  const saveDonationInformation = donationInfo => dispatch(saveDonationInfo(donationInfo));

  const resetEnrollment = () => dispatch(resetEnrollmentState());

  return {
    isScreening,
    isPersonalInfo,
    cancers,
    studyInfoStatus,
    studyInfo,
    fetchStudyInfo,
    saveDonationInformation,
    resetEnrollment
  };

};