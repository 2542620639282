import { StaticStuffService } from "services/staticStuffService";
import { call, put } from "redux-saga-test-plan/matchers";
import {
  failure, fetchStaticStuff,
  pending,
  success
} from "../../redux/slices/staticStuffSlice";
import { takeLatest } from "redux-saga/effects";

export const staticStuffSaga = [
  takeLatest(fetchStaticStuff, handleFetchStaticStuff),
];

function* handleFetchStaticStuff() {
  try {
    yield put(pending());
    const response = yield call(StaticStuffService.getStaticStuff);
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}