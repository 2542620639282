import { PatternCircularProgress } from "shared/components";
import { SuccessView } from "./index";
import { selectConsent } from "../../redux/selectors/consentSelector";
import { selectDonationInfoStatus } from "../../redux/selectors/donationInfoSelector";
import { useSelector } from "react-redux";

const SuccessContainer = () => {
  const status = useSelector(selectDonationInfoStatus);
  const consent = useSelector(selectConsent);

  if (status !== "succeeded" && !selectConsent) return <PatternCircularProgress />;

  return (
    <SuccessView consent={consent}/>
  );
};

export default SuccessContainer;