import { getConsent, resetConsentStep, setConsentFormBirthday, setConsentStep } from "../../redux/slices/consentSlice";
import {
  selectConsent,
  selectConsentContactInfo,
  selectConsentFormBirthday,
  selectConsentStatus,
  selectConsentStudyName,
  selectCurrentConsentStep,
  selectMaxConsentStep
} from "../../redux/selectors/consentSelector";
import { useDispatch, useSelector } from "react-redux";

export const useConsent = () => {

  const dispatch = useDispatch();

  const birthday = useSelector(selectConsentFormBirthday);
  const consentStatus = useSelector(selectConsentStatus);
  const consent = useSelector(selectConsent);
  const consentStudyName = useSelector(selectConsentStudyName);
  const consentContactInfo = useSelector(selectConsentContactInfo);
  const currentConsentStep = useSelector(selectCurrentConsentStep);
  const maxConsentStep = useSelector(selectMaxConsentStep);
  const fetchConsent = id => dispatch(getConsent({ study_id: id}));
  const updateConsentStep = payload => dispatch(setConsentStep(payload));
  const updateConsentFormBirthday = payload => dispatch(setConsentFormBirthday(payload));

  const resetConsent = () => dispatch(resetConsentStep());

  return {
    birthday,
    consentStatus,
    consent,
    consentContactInfo,
    currentConsentStep,
    maxConsentStep,
    consentStudyName,
    fetchConsent,
    updateConsentFormBirthday,
    updateConsentStep,
    resetConsent
  };
};